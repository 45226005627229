import React from "react"
import Hero from "../components/hero"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import "../styles/index.css"
import { BurgerMenuIconAnimation } from "../constants/layoutStyle"
import BurgerMenu from "../components/burgerMenu"
import { useQueryParam, StringParam } from "use-query-params"
import Search from "../components/search";
import TopPageLayout from "../components/topPageLayout";

export default function Home({ data }) {
    const [searchword, setSearchword] = useQueryParam("searchword", StringParam);
    if (searchword == null) return (<></>) // チラつき回避
    return (
    <>
      <SEO />
      <TopPageLayout>
        <BurgerMenu iconAnination={BurgerMenuIconAnimation.Visible} />
        <div className="top-main">
          <Hero />
            <main className="main-area">
                <Search searchStr={searchword}/>
            </main>
        </div>
      </TopPageLayout>
    </>
  )
}
export const query = graphql`
  query allContentfulPost2 {
    allContentfulPost(
      filter: { node_locale: { eq: "ja" } }
      sort: { fields: [publicationDate], order: [DESC] }
    ) {
      edges {
        node {
          title
          indexImage {
            title
            gatsbyImageData
          }
          images {
            title
            gatsbyImageData
          }
          description {
            description
          }
          slug
          updatedAt(locale: "ja-JP", formatString: "YYYY年MM月DD日")
          publicationDate
        }
      }
    }
    allContentfulSiteLogos(filter: { node_locale: { eq: "ja" } }) {
      edges {
        node {
          heroImage {
            url
          }
        }
      }
    }
  }
`
